<template>
  <div class="container">
    <div class="personal__title title">Личные данные</div>
    <form class="personal-form" @submit.prevent="sendRequest" ref="form">
      <div class="personal-form__item">
        <div class="personal-form__input">
          <form-text
            :label="'Логин *'"
            :id="'login'"
            v-model="login"
            :pattern="'+# (###) ### ## ##'"
            :required="true"
          />
        </div>
        <div class="personal-form__text">
          В качестве логина необходимо использовать номер вашего телефона.
          Другие данные нежелательны и могут привести к неработоспособности профиля!
        </div>
      </div>

      <div class="personal-form__item">
        <div class="personal-form__input">
          <form-text
            v-model="fio"
            :label="'Фамилия Имя Отчество *'"
            :required="true"
          />
        </div>
        <div class="personal-form__text">
          Заполните, чтобы мы знали, как к вам обращаться
        </div>
      </div>

      <div class="personal-form__item">
        <div class="personal-form__input">
          <form-text
            :label="'Телефон *'"
            v-model="personalPhone"
            :pattern="'+# (###) ### ## ##'"
            :required="true"
          />
        </div>
        <div class="personal-form__text">
          Необходим для уточнения деталей заказа
        </div>
      </div>

      <div class="personal-form__item">
        <div class="personal-form__input">
          <form-text
            :label="'Email *'"
            v-model="email"
            :required="true"
          />
        </div>
        <div class="personal-form__text">
          Для отправки уведомлений о статусе заказа
        </div>
      </div>
      <div class="personal-form__item">
        <button class="btn personal-form__submit">Сохранить изменения</button>
      </div>
    </form>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";
import store from '@/store'

export default {
  components: {
    FormText,
  },

  data() {
    return {
      fio: null,
      login: null,
      email: null,
      personalPhone: null,
    };
  },

  mounted() {
   this.updateProfile();
  },

  methods: {
    updateProfile() {
      let profile = this.$store.state.profile;

      this.login = profile.login;
      this.email = profile.email;
      this.fio = profile.lastName + ' ' + profile.name + ' ' + profile.secondName;
      this.personalPhone = profile.personalPhone;
    },
    sendRequest() {
      const data = {
        'LOGIN': this.login,
        'FIO': this.fio,
        'PERSONAL_PHONE': this.personalPhone,
        'EMAIL': this.email
      };

      this.$store
        .dispatch("PERSONAL_UPDATE", data)
        .then((response) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", response.data);
        })
        .catch((error) => {
          this.$store.commit("SET_MESSAGE_MODAL_DATA", error.response.data);
        })
        .finally(() => {
          store.dispatch("FIRST_LOAD_INFORMATION").then(() => {
            this.this.updateProfile()
          });
        });
    },
  },
};
</script>
